import useLogger from '@package/logger/src/use-logger';

import type { EnvironmentService } from '../environment/environment-service';
import { globalSettings } from '../global-settings';
import { RouterService } from '../router/router-service';
import type { IStorageService } from '../storage/storage-service';
import { StorageKeys } from '../storage/storage-types';
import type { AbstractManufacturerService } from './abstract-manufacturer-service';
import { DesktopService } from './desktop-service';
import { Device, OperationSystem, SetVolumeLevel } from './device-types';
import { PerformanceFeatureType } from './performance-features';
import { TizenService } from './tizen-service';
import { VidaaService } from './vidaa-service';
import { WebOsService } from './webos-service';

declare global {
  interface Window {
    platform?: string;
  }
}

const logger = useLogger('device-service');
const isClient = typeof window !== 'undefined';
const platform = isClient ? (window.navigator.platform ?? window.navigator?.userAgentData?.platform) : '';

const defaultUserAgent = isClient ? window.navigator.userAgent : '';

const getCurrentOperationSystem = (userAgent = defaultUserAgent): OperationSystem => {
  if (!isClient) {
    return OperationSystem.Desktop;
  }

  let os = OperationSystem.Desktop;

  if (/Mac/.test(platform)) {
    os = OperationSystem.Desktop;
  } else if (/Android/.test(userAgent)) {
    os = OperationSystem.Mobile;
  } else if (/WebAppManager/.test(userAgent)) {
    os = OperationSystem.WebOs;
  } else if (/WebOs/.test(userAgent)) {
    os = OperationSystem.WebOs;
  } else if (/Web0S/.test(userAgent)) {
    os = OperationSystem.WebOs;
  } else if (/Tizen/.test(userAgent)) {
    os = OperationSystem.Tizen;
  } else if (/VIDAA/.test(userAgent)) {
    os = OperationSystem.Vidaa;
  } else if (/Hisense/.test(userAgent)) {
    os = OperationSystem.Vidaa;
  } else if (/Win/.test(platform)) {
    os = OperationSystem.Desktop;
  } else if (/Linux/.test(platform)) {
    os = OperationSystem.Desktop;
  } else if (/iP/.test(platform)) {
    os = OperationSystem.Mobile;
  } else if (/AndroidTV/.test(platform)) {
    os = OperationSystem.AndroidTV;
  }

  return os;
};

export class DeviceService {
  private readonly _os: OperationSystem;
  private readonly manufacturerService: AbstractManufacturerService;

  constructor(
    private readonly storageService: IStorageService,
    private readonly environmentService: EnvironmentService,
    private readonly routerService: RouterService,
    forcedUserAgent?: string,
  ) {
    this._os = getCurrentOperationSystem(forcedUserAgent);

    switch (this._os) {
      case OperationSystem.Tizen:
        this.manufacturerService = new TizenService();
        break;
      case OperationSystem.WebOs:
        this.manufacturerService = new WebOsService();
        break;
      case OperationSystem.Vidaa:
        this.manufacturerService = new VidaaService();
        break;
      default:
        this.manufacturerService = new DesktopService(this, this.routerService);
    }
  }

  public async getFullDeviceInfo() {
    try {
      const data = await this.manufacturerService.getDeviceInfo();

      return data;
    } catch (error) {
      logger.error(String(error));

      return undefined;
    }
  }

  public getOSInformation() {
    return getCurrentOperationSystem();
  }

  public init() {
    const tokens = this.getAvailableFeatures().map((feat) => `has-${feat}`);
    document.documentElement.classList.add(...tokens);

    return this.manufacturerService.init();
  }

  public reloadApp() {
    return window.location.reload();
  }

  public getDevice(): Device {
    const name = this.manufacturerService.getHumanReadableDeviceName();

    return {
      uid: this.deviceUUID,
      app_version: this.environmentService.getVariable<() => string>('appVersion')(),
      os: this.os,
      name,
      type: 'tv',
      manufacturer: this.manufacturerService.getManufacturerName(),
    };
  }

  /**
   * @return {boolean}
   */
  public isModernBuild(): boolean {
    return globalSettings.vueVersion === 'vue3';
  }

  public resolveStartupDeeplink(): Promise<void> {
    return this.manufacturerService.resolveStartupDeeplink();
  }

  public setVolume(volume: SetVolumeLevel): void {
    return this.manufacturerService.setVolume(volume);
  }

  public getVolume(): number {
    return this.manufacturerService.getVolume();
  }

  public getAvailableFeatures() {
    return this.manufacturerService.getAvailableFeatures();
  }

  public getFeatureState(feature: PerformanceFeatureType): boolean {
    return this.manufacturerService.getFeatureState(feature);
  }

  public get deviceUUID() {
    let deviceUUID = this.storageService.getItem(StorageKeys.DeviceUUID);

    if (!deviceUUID) {
      deviceUUID = globalSettings.uuidv4();
      this.storageService.setItem(StorageKeys.DeviceUUID, deviceUUID);
    }

    return deviceUUID as string;
  }

  public getVisitorId(): string {
    let visitorId = this.storageService.getItem(StorageKeys.Visitor);

    if (!visitorId) {
      visitorId = globalSettings.uuidv4();
      this.storageService.setItem(StorageKeys.Visitor, visitorId);
    }

    return visitorId as string;
  }

  public get os() {
    return this._os;
  }

  public exit() {
    return this.manufacturerService.exit();
  }
}
