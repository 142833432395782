<template></template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { type AppKeyboardEvent, isUndefined, TvKeyCode } from '@package/sdk/src/core';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useEpisodesNavigationActions from '@PLAYER/player/modules/hooks/use-episodes-navigation-actions';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { SmartTvCode } from '@PLAYER/player/modules/keyboard/smart-tv-key-codes';
import useKeyboardShortcuts from '@PLAYER/player/modules/keyboard/use-keyboard-shortcuts';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import usePlaybackRewindActions from '@PLAYER/player/modules/video/use-playback-rewind-actions';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import getSmartTVKeyWithPlatform from '@PLAYER/player/versions/smart/modules/keyboard/get-smarttv-key-with-platform';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

import { PlayerFocusKeys } from '../../modules/smart-navigation/focus-keys';

const videoControlsStore = useVideoControlsStore();
const layoutStore = useLayoutStore();

const videoInteractions = useVideoInteractions();

const playbackActions = usePlaybackActions();
const { isVOD, isKinom } = useProjector();
const { isShownPopup } = storeToRefs(layoutStore);

const playbackRewindActions = usePlaybackRewindActions();
const keyboardShortcuts = useKeyboardShortcuts();
const episodesNavigationActions = useEpisodesNavigationActions();

const { normalizedDuration, normalizedDisplayedCurrentTime } = useVideoPlayerVariables();
const videoUIStore = useVideoUIStore();

const { isPlaying, displayedCurrentTime } = storeToRefs(videoUIStore);
const { isShouldPlayVideoAfterRewind } = storeToRefs(useVideoControlsStore());
const videoIsPlaying = ref(isPlaying.value);
const { SpatialNavigation } = useSmartNavigatable();

const REPEAT_TIMEOUT = 350;

let currentTimestamp = '';
let autoRepeatMultiplier = 1;

let currentTimestampTimeoutId: number;
let repeatTimerIntervalId: number;
let draggingTimeoutId: number;

let shouldPlayVideo = false;

const repeatKeycodeMap = { [SmartTvCode.Right]: false, [SmartTvCode.Left]: false };

const runSmartPlaybackTimeout = () => {
  const numberedTimestamp = Number(currentTimestamp);

  if (Number.isNaN(numberedTimestamp)) {
    // С NaN не работаем
    return;
  }

  if (currentTimestampTimeoutId) {
    window.clearTimeout(currentTimestampTimeoutId);
  }

  currentTimestampTimeoutId = window.setTimeout(() => {
    if (!numberedTimestamp) {
      return;
    }

    currentTimestamp = '';
    const requestedTime = numberedTimestamp * 60;

    if (requestedTime >= normalizedDuration.value) {
      return;
    }

    videoInteractions.changeCurrentTime({
      seconds: requestedTime,
      manually: true,
    });
  }, 1500);
};

const initializeSmartPlayback = () => {
  for (let i = 0; i < 10; i++) {
    const key = `N${i}` as keyof typeof TvKeyCode;

    const code = getSmartTVKeyWithPlatform(TvKeyCode[key]);

    keyboardShortcuts.addGlobalShortcut(code, () => {
      currentTimestamp += i;
      runSmartPlaybackTimeout();
    });
  }
};

const canApplySmartTvCommand = (_: AppKeyboardEvent) => {
  // const isNativeRewindOrForward = data.event.keyCode === TvKeyCode.REWIND || data.event.keyCode === TvKeyCode.FORWARD;

  return SpatialNavigation?.getCurrentFocusKey() === PlayerFocusKeys.PLAYER_TIMELINE;
};

const handleMultiplier = (repeat: boolean) => {
  if (repeat && autoRepeatMultiplier <= 7) {
    autoRepeatMultiplier += 0.2;
  }

  if (!repeat) {
    autoRepeatMultiplier = 1;
  }
};

const handleRewindAction = () => {
  playbackActions.doPause({ manual: false });

  if (isPlaying.value) {
    shouldPlayVideo = true;
  }

  if (draggingTimeoutId) {
    window.clearTimeout(draggingTimeoutId);
  }

  draggingTimeoutId = window.setTimeout(() => {
    if (!shouldPlayVideo) {
      return;
    }

    playbackActions.doPlay({
      manual: false,
    });
    shouldPlayVideo = false;
  }, 1000);
};

useEffect(() => {
  if (isKinom.value) {
    return;
  }

  if (isVOD.value) {
    initializeSmartPlayback();

    keyboardShortcuts.addGlobalShortcut(SmartTvCode.Next, () => episodesNavigationActions.requestNextEpisode(true));
    keyboardShortcuts.addGlobalShortcut(SmartTvCode.Prev, () => episodesNavigationActions.requestPreviousEpisode());
  }

  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Stop, () => {
    if (isPlaying.value) {
      videoControlsStore.setCurrentAction({ type: 'pause' });
    }

    playbackActions.doPause();
  });

  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Play, () => {
    if (!isPlaying.value) {
      videoControlsStore.setCurrentAction({ type: 'play' });
    }

    playbackActions.doPlay();
  });

  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Pause, () => {
    if (isPlaying.value) {
      videoControlsStore.setCurrentAction({ type: 'pause' });
    }

    playbackActions.doPause();
  });

  let smartTvRewindKeyTimeoutId: number;

  const onForward = (data: AppKeyboardEvent) => {
    if (!canApplySmartTvCommand(data)) {
      return;
    }

    handleRewindAction();

    if (repeatKeycodeMap[SmartTvCode.Left]) {
      autoRepeatMultiplier = 1;
      repeatKeycodeMap[SmartTvCode.Left] = false;
    }

    if (repeatTimerIntervalId) {
      // tizen event repeat fix
      repeatKeycodeMap[SmartTvCode.Right] = true;
      window.clearInterval(repeatTimerIntervalId);
    }

    repeatTimerIntervalId = window.setInterval(() => {
      repeatKeycodeMap[SmartTvCode.Right] = false;
    }, REPEAT_TIMEOUT);

    handleMultiplier(data.event.repeat || repeatKeycodeMap[SmartTvCode.Right]);

    if (smartTvRewindKeyTimeoutId) {
      window.clearTimeout(smartTvRewindKeyTimeoutId);
    }

    if (isUndefined(isShouldPlayVideoAfterRewind.value)) {
      videoControlsStore.setIsShouldPlayVideoAfterRewind(isPlaying.value);
    }

    const newTime =
      displayedCurrentTime.value +
      ConstantsConfigPlayer.getProperty('rewindTimeSecondsSmartTvMax') * autoRepeatMultiplier;

    videoUIStore.setDisplayedCurrentTime(newTime);

    smartTvRewindKeyTimeoutId = window.setTimeout(() => {
      playbackRewindActions.rewindTimeForwardExact(newTime, false);
    }, ConstantsConfigPlayer.getProperty('rewindPlayTimeoutMs'));
  };

  const onRewind = (data: AppKeyboardEvent) => {
    if (!canApplySmartTvCommand(data)) {
      return;
    }

    handleRewindAction();

    if (repeatKeycodeMap[SmartTvCode.Right]) {
      autoRepeatMultiplier = 1;
      repeatKeycodeMap[SmartTvCode.Right] = false;
    }

    if (repeatTimerIntervalId) {
      // tizen event repeat fix
      repeatKeycodeMap[SmartTvCode.Left] = true;
      window.clearInterval(repeatTimerIntervalId);
    }

    repeatTimerIntervalId = window.setInterval(() => {
      repeatKeycodeMap[SmartTvCode.Left] = false;
    }, REPEAT_TIMEOUT);

    handleMultiplier(data.event.repeat || repeatKeycodeMap[SmartTvCode.Left]);

    if (smartTvRewindKeyTimeoutId) {
      window.clearTimeout(smartTvRewindKeyTimeoutId);
    }

    if (isUndefined(isShouldPlayVideoAfterRewind.value)) {
      videoControlsStore.setIsShouldPlayVideoAfterRewind(isPlaying.value);
    }

    const newTime =
      displayedCurrentTime.value -
      ConstantsConfigPlayer.getProperty('rewindTimeSecondsSmartTvMax') * autoRepeatMultiplier;

    videoUIStore.setDisplayedCurrentTime(newTime);

    smartTvRewindKeyTimeoutId = window.setTimeout(() => {
      playbackRewindActions.rewindTimeBackwardExact(newTime, false);
    }, ConstantsConfigPlayer.getProperty('rewindPlayTimeoutMs'));
  };

  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Forward, onForward);
  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Rewind, onRewind);
  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Right, onForward);
  keyboardShortcuts.addGlobalShortcut(SmartTvCode.Left, onRewind);

  keyboardShortcuts.addGlobalShortcut(
    SmartTvCode.Enter,
    (event) => {
      if (!canApplySmartTvCommand(event)) {
        return;
      }

      playbackActions.togglePlayback();
    },
    { useDefaultPrevent: true },
  );

  watch(isShownPopup, (val) => {
    // возобновляем просмотр видео если до открытия попапа видео проигрывалось
    if (!val && videoIsPlaying.value) {
      playbackActions.doPlay();
    }

    if (!val) {
      return;
    }

    videoIsPlaying.value = isPlaying.value;
    playbackActions.doPause();

    window.$setOnPressBackCallback(() => layoutStore.setPopup(null), true);
  });

  return () => {
    if (currentTimestampTimeoutId) {
      window.clearTimeout(currentTimestampTimeoutId);
    }

    if (repeatTimerIntervalId) {
      window.clearInterval(repeatTimerIntervalId);
    }

    if (draggingTimeoutId) {
      window.clearTimeout(draggingTimeoutId);
    }
  };
});
</script>
