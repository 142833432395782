import { AbstractManufacturerService } from './abstract-manufacturer-service';
import { VidaaFeatureMap } from './performance-features';

export namespace VidaaOS {
  export interface GetSupportForHDRInfo {
    HDR10: boolean;
    HLS: boolean;
    HDR10Plus: boolean;
    DOVI: boolean;
  }
}

declare const vidaatv: {
  getDeviceID(): string;

  getBrand(): string;

  getModelName(): string;

  getFirmWareVersion(): string;

  getChipSetName(): string;

  getOSVersion(): string;

  getFeatureCode(): string;

  getCapabilityCode(): string;

  get4KSupportState(): boolean;

  getSupportForHDR(): 'not-support' | 'HDR10' | 'HLG' | 'HDR10+HLG';

  getSupportForHDRInfo(): VidaaOS.GetSupportForHDRInfo;

  getSupportForDolbyAtmos(): boolean;

  getMute(): 1 | 0; // 1 is muted, 0 - is unmuted
  getVolume(): number; // 0 - 100;
  getTTSEnable(): 1 | 0; // 1 is enable
  getTTSLanguage(): string;

  getTTSVolume(): number; // 0 - 100
  getCountryCode(): string;

  getMenuLanguageCode(): string;

  setStartBandwidth(): boolean;

  getNetStatus(): boolean;

  getNetType(): 'off' | 'wired' | 'wireless';

  getIPAddress(): string;

  disableVKB(): boolean; // true if is disabled
  enableVKB(): boolean; // true if is disabled
  getUuid(): string; // from U6
};

declare global {
  interface Window {}
}

export class VidaaService extends AbstractManufacturerService {
  constructor() {
    super(new VidaaFeatureMap());
  }

  public setVolume(volume: number) {
    console.info(volume);
  }

  public getVolume(): number {
    return 0;
  }

  public resolveStartupDeeplink(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public getHumanReadableDeviceName(): string {
    return `Hisense ${vidaatv.getModelName()}`;
  }

  public getManufacturerName(): string {
    return 'Hisense';
  }

  public getDeviceInfo(): unknown {
    return {
      appId: 'viju',
      modelName: vidaatv.getModelName(),
    };
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public exit() {
    try {
      window.close();
      return true;
    } catch (error) {
      return false;
    }
  }
}
