<template>
  <div ref="el" :class="$style.wrapper">
    <div
      :class="{ [$style.background]: true, [$style.backgroundTransition]: enableBackgroundTransition }"
      :style="{ backgroundImage: `url(${channelBackground})` }"
    >
      <div :class="$style.gradient" />
    </div>

    <div ref="channelsContainer" :class="$style.channels">
      <ScrollViewport tag="ul" :class="$style.list" :y="offsetTopPx" role="list" @vue:mounted="onVNodeMounted">
        <li v-for="(channel, index) in channels" :key="channel.id" ref="list">
          <NavigatableItem
            :tag="AppSlotButton"
            :focus-key="FocusKeys.CHANNEL(index)"
            :class="{
              [$style.item]: true,
              [$style.itemSelected]: activeChannel?.id === channel.id,
            }"
            @active="handleActiveItem(channel, list, index)"
            @click="handleChannelClick(channel)"
          >
            <app-image :class="$style.logo" :src="channel.logo" :width="330" />
          </NavigatableItem>
        </li>
      </ScrollViewport>
    </div>

    <div v-if="isVNodeMounted" :class="$style.details">
      <h2 v-if="activeChannel?.currentProgram?.title" :class="$style.title">
        {{ activeChannel?.currentProgram?.title }}
      </h2>

      <div v-if="activeChannel?.currentProgram.startTime" :class="$style.time">
        {{ getTvProgramStartTime(activeChannel?.currentProgram.startTime) }}
        —
        {{ getTvProgramEndTime(activeChannel?.currentProgram.startTime, activeChannel?.currentProgram.duration) }}
      </div>

      <div
        v-if="isShowAboutMovie && activeChannel?.currentProgram && activeChannel?.lockedInfo"
        :class="$style.message"
      >
        {{ activeChannel.lockedInfo }}
      </div>

      <div v-if="isShowAboutMovie && activeChannel?.currentProgram" :class="$style.controls">
        <NavigatableItem
          :tag="AppButton"
          :focus-key="FocusKeys.CHANNELS_CONTROL_OPEN"
          :active-class="$style.controlsActive"
          :text="activeChannelButtonText"
          :disabled="activeChannel?.lockedInfo"
          @click="
            openContentPage({
              title: activeChannel.currentProgram.title as string,
              contentType: activeChannel.currentProgram.contentType as MediaContentType,
              id: activeChannel.currentProgram.contentId as string,
            })
          "
        />
      </div>

      <div v-if="false" :class="$style.price" />

      <footer v-if="activeChannel?.availability && activeChannel?.nextProgram?.title" :class="$style.footer">
        <div :class="$style.footerTitle">
          {{ $t('pages.channels.nextMovieText') }}
        </div>

        <div :class="$style.program">
          <div v-if="activeChannel?.nextProgram?.startTime" :class="$style.programTime">
            {{ getTvProgramStartTime(activeChannel?.nextProgram?.startTime || '') }}
          </div>

          <div :class="$style.programTitle">
            {{ activeChannel?.nextProgram?.title }}
          </div>
        </div>
      </footer>
    </div>

    <PartnerSubscriptionModal v-if="partnerSubscriptionModalShown" @close="closePartnerSubscriptionModal" />
  </div>
</template>

<script setup lang="ts">
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { useTvPageAnalytics } from '@package/sdk/src/analytics';
import { type Channel, ContentAccessTypes } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { DisposableStore, indexOutOfRange, TvKeyCode, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import DefaultChannelsBackground from '@SMART/assets/images/default-channels-background.webp';
import {
  analyticService,
  CatalogGetters,
  CatalogState,
  channelsService,
  FocusKeys,
  keyboardEventHandler,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  TvChannelGetters,
  TvChannelState,
  useCatalogStore,
  useImage,
  useMediaContentActions,
  useSessionStore,
  useTvChannelActions,
  useTvChannelsStore,
} from '@SMART/index';
import { addSeconds, format, isValid, parseISO } from 'date-fns';
import { computed, onActivated, onBeforeUnmount, onMounted, provide, ref } from 'vue';
import { useRoute } from 'vue-router';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

import PartnerSubscriptionModal from './components/PartnerSubscriptionModal.vue';

const route = useRoute();

const catalogStore = useCatalogStore();
const tvChannelsStore = useTvChannelsStore();
const tvPageAnalytics = useTvPageAnalytics(analyticService.sender);

const { onVNodeMounted, isVNodeMounted } = useVNodeMounted({ withTimeout: true });

const { selectedItem } = storeToRefs<CatalogState, CatalogGetters, unknown>(catalogStore);
const { channels, selectedChannelItem } = storeToRefs<TvChannelState, TvChannelGetters, unknown>(tvChannelsStore);
const { isActiveSubscription, isPartnerSubscription, subscription, currentOffer } = storeToRefs<
  SessionState,
  SessionGetters,
  unknown
>(useSessionStore());

const { openTvChannelPage } = useTvChannelActions();
const { openContentPage } = useMediaContentActions();
const { isAuth } = useSessionVariables();
const { isCDNLink, getCDNLink } = useCDNImage();
const { loadImage } = useImage();

const channelsContainer = ref<HTMLElement>();
const list = ref<HTMLElement[]>();
const offsetTopPx = ref(0);
const activeChannel = ref<Channel>();
const channelBackground = ref();
const partnerSubscriptionModalShown = ref(false);

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.CHANNELS_PAGE,
  saveLastFocusedChild: true,
});

provide('parentFocusKey', focusKey.value);

const disposableStore = new DisposableStore();

const enableBackgroundTransition = ref(false);

const handleActiveItem = (channel: Channel, list: HTMLElement[] = [], index: number) => {
  enableBackgroundTransition.value = false;

  // todo: direction for smarttv not correct, fix to actual: top | bottom
  tvPageAnalytics.onClickTvBeltSwiped(index > selectedItem.value?.rowId ? 'right' : 'left');
  catalogStore.updateSelectedItem({ rowId: index, value: channel });

  const background = channel.currentProgram?.background ?? (channel.background as string);
  const backgroundSrc = !isCDNLink(background) ? getCDNLink(background, 250) : background;

  const onLoadHandler = async () => {
    if (channel.id !== activeChannel.value?.id) {
      return;
    }

    channelBackground.value = backgroundSrc;

    window.setTimeout(() => {
      enableBackgroundTransition.value = true;

      window.setTimeout(() => {
        if (activeChannel.value !== channel) {
          return;
        }

        const src = getCDNLink(background, 1000);

        channelBackground.value = src;
      }, 1000);
    }, 200);
  };

  const onErrorHandler = () => {
    if (channel.id !== activeChannel.value?.id) {
      return;
    }

    channelBackground.value = DefaultChannelsBackground;
  };

  loadImage(backgroundSrc, onLoadHandler, onErrorHandler);

  const element = list[index];

  if (!element) {
    throw new UnexpectedComponentStateError('element');
  }

  const maxOffsetTop = element.parentElement?.scrollHeight - channelsContainer.value?.offsetHeight;
  const offset = element.offsetTop === 0 ? 0 : element.offsetTop;

  offsetTopPx.value = offset > maxOffsetTop && maxOffsetTop > 0 ? maxOffsetTop : offset;
  activeChannel.value = channel;

  tvChannelsStore.updateSelectedChannelItem({ focusKey: FocusKeys.CHANNEL(index) });
};

const handleChannelClick = (channel: Channel) => {
  if (
    channel.accessKind === ContentAccessTypes.Subscription &&
    isPartnerSubscription.value &&
    !isActiveSubscription.value
  ) {
    partnerSubscriptionModalShown.value = true;
    return;
  }

  openTvChannelPage(channel);
};

const closePartnerSubscriptionModal = async () => {
  partnerSubscriptionModalShown.value = false;
  focusSelf();
};

const activeChannelButtonText = computed(() =>
  playerHelpers.getPlayButtonText({
    isActiveSubscription: isActiveSubscription.value,
    isAuth: isAuth.value,
    isPartnerSubscription: isPartnerSubscription.value,
    isLive: true,
    isVOD: false,
    subscription: subscription.value,
    offer: currentOffer.value,
  }),
);

const getTvProgramStartTime = (startTime: string): string => {
  const time = parseISO(startTime);

  return isValid(time) ? format(time, 'HH:mm') : '';
};

const getTvProgramEndTime = (startTime: string, duration = 0): string => {
  const time = parseISO(startTime);

  if (isValid(time)) {
    return format(addSeconds(time, duration), 'HH:mm');
  }

  return '';
};

const isShowAboutMovie = computed(
  () =>
    activeChannel.value?.availability &&
    activeChannel.value?.currentProgram?.contentId &&
    activeChannel.value?.currentProgram?.contentType,
);

const selectChannelRowByIndex = async (index: number) => {
  let idx = index;

  if (idx <= 0) {
    idx = 0;
  }

  if (idx >= channels.value.length) {
    idx = channels.value.length - 1;
  }

  SpatialNavigation.setFocus(FocusKeys.CHANNEL(idx));
};

const onPrevChannel = () => selectChannelRowByIndex((selectedItem.value?.rowId || 0) - 1);

const onNextChannel = () => selectChannelRowByIndex((selectedItem.value?.rowId || 0) + 1);

const selectChannelById = async () => {
  const query = Object.assign({}, route.query);

  const index = channels.value.findIndex((channel: Channel) => channel.id === query.channelId);

  if (!indexOutOfRange(index)) {
    delete query.channelId;

    await routerService.replace({ query });
    SpatialNavigation.setFocus(FocusKeys.CHANNEL(index));
  }
};

const restoreSelectedChannelItem = () => {
  const focusKey = selectedChannelItem.value.focusKey;

  if (SpatialNavigation.doesFocusableExist(focusKey)) {
    return SpatialNavigation.setFocus(focusKey);
  }

  SpatialNavigation.setFocus(FocusKeys.CHANNEL(selectedItem.value?.rowId || 0));
};

onActivated(async () => {
  tvPageAnalytics.onShowTvChannelsPage();

  if (!channels.value.length) {
    return;
  }

  restoreSelectedChannelItem();
});

onMounted(async () => {
  await channelsService.fetchChannels();

  disposableStore.add(keyboardEventHandler.on(TvKeyCode.CHANNEL_DOWN, onNextChannel));
  disposableStore.add(keyboardEventHandler.on(TvKeyCode.CHANNEL_UP, onPrevChannel));

  if (route.query.channelId) {
    return selectChannelById();
  }

  focusSelf();
});

onBeforeUnmount(() => {
  disposableStore.dispose();

  catalogStore.updateSelectedItem(null);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  display: flex;
  flex-direction: row;
  margin-left: adjust.adjustPx(140px);
  padding-top: adjust.adjustPx(60px);
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &Transition {
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
  }
}

.gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(8, 17, 16, 1) 8.49%, rgba(8, 17, 16, 0) 99.94%);
}

.gradient::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: adjust.adjustPx(524px);
  background: linear-gradient(180deg, rgba(8, 17, 16, 1) -13.03%, rgba(8, 17, 16, 0) 100%);
  content: '';
}

.gradient::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: adjust.adjustPx(360px);
  background: linear-gradient(180deg, rgba(8, 17, 16, 0) 0%, rgba(8, 17, 16, 1) 100%);
  content: '';
}

.channels {
  position: absolute;
  top: adjust.adjustPx(60px);
  bottom: adjust.adjustPx(60px);
  left: adjust.adjustPx(160px);
  width: adjust.adjustPx(330px);
}

.list {
  position: relative;

  li {
    margin-top: adjust.adjustPx(20px);

    &:first-child {
      margin-top: 0;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: adjust.adjustPx(40px) adjust.adjustPx(48px);
  width: adjust.adjustPx(330px);
  height: adjust.adjustPx(132px);
  border-radius: adjust.adjustPx(24px);
  background-color: var(--color-bg-secondary);
  outline: none;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: var(--color-bg-secondary);
  }

  &Selected {
    &::after {
      box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-stroke-border-active);
    }
  }
}

.logo {
  width: adjust.adjustPx(234px);
}

.details {
  position: absolute;
  top: adjust.adjustPx(60px);
  bottom: adjust.adjustPx(60px);
  left: adjust.adjustPx(550px);
  right: adjust.adjustPx(60px);
}

.title {
  color: var(--color-text-primary);

  @include smartTvFonts.SmartTvTitle-2();
}

.time {
  color: var(--color-text-primary);
  white-space: nowrap;
  margin-top: adjust.adjustPx(12px);

  @include smartTvFonts.SmartTvBody-1();
}

.message {
  color: var(--color-text-secondary);
  margin-top: adjust.adjustPx(12px);

  @include smartTvFonts.SmartTvBody-2();
}

.controls {
  margin-top: adjust.adjustPx(32px);

  &Active {
    background-color: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.price {
  color: var(--color-text-secondary);
  margin-top: adjust.adjustPx(12px);

  @include smartTvFonts.SmartTvCaption-1();
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footerTitle {
  color: var(--color-text-secondary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvHeadline-3();
}

.program {
  display: flex;
  margin-top: adjust.adjustPx(32px);
  color: var(--color-text-primary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvBody-1();

  &Time {
    padding-right: adjust.adjustPx(40px);
  }

  &Title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
