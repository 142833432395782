import '@package/dsml-js/src/core/index';
import 'normalize.css';
import './styles/app.scss';
import './polyfills';
import '@package/ui/src/styles/style.css';
import '@package/ui/src/styles/custom-normalize.scss';

import SmartTvConstantsInstance from '@package/constants/code/constants-config-smart-tv';
import useLogger from '@package/logger/src/use-logger';
import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import { MediaPlayerAnalyzerInstance } from '@package/media-player-analyzer/src/plugin/media-player-instance';
import { AnalyticEventNameNew, AnalyticPageName } from '@package/sdk/src/analytics';
import { FeatureToggle } from '@package/sdk/src/api';
import SmartTvCriticalErrorView from '@package/smarttv-base/src/components/errors/SmartTvCriticalErrorView.vue';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import * as Sentry from '@sentry/vue';
import {
  AlertMessageTypes,
  alertService,
  analyticService,
  AppLanguageManager,
  authService,
  customEventsService,
  deviceService,
  environmentService,
  featuresService,
  globalSettings,
  i18n,
  longPressDirective,
  OperationSystem,
  requestService,
  RouterPage,
  routerService,
  setupSentry,
  telemetry,
  useContentStore,
  useSessionStore,
} from '@SMART/index';
import Axios, { isCancel } from 'axios';
import cryptoJs from 'crypto-js';
import * as dateFns from 'date-fns';
import { ru } from 'date-fns/locale';
import JwtDecode from 'jwt-decode';
import * as QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import {
  computed,
  createApp,
  inject,
  nextTick,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import * as router from 'vue-router';
import Vuex from 'vuex';

import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import { isSafari } from '@/sdk/base/dom';
import { remoteControlService } from '@/services/remote-control/remote-control-service';

import App from './App.vue';
import routes from './services/router/routes';

dateFns.setDefaultOptions({ locale: ru });

const logger = useLogger('main.ts');

const setupLoggers = () => {
  logger.level = SmartTvConstantsInstance.getProperty('logLevel');
};

const setupPlayer = () => {
  SmartTvVijuPlayer.setCurrency('₽');
  SmartTvVijuPlayer.setLang('ru');
  SmartTvVijuPlayer.addPlugin(MediaPlayerAnalyzerInstance);
};

const showAppVersion = () => {
  console.info(
    '%c Viju smarttv version: ' + '%c '.concat(environmentService.getVariable<() => string>('appVersion')() || '', ' '),
    'color: #fff; background: #0A1E1F',
    'color: #fff; background: #cc6666',
  );
};

const hideDebugData = () => {
  let debug = document.getElementById('debug1');

  if (debug) {
    debug.hidden = true;
  }

  debug = document.getElementById('debug2');
  if (debug) {
    debug.hidden = true;
  }

  debug = document.getElementById('debug3');
  if (debug) {
    debug.hidden = true;
  }
};

const setupGlobalSettings = () => {
  globalSettings.axios = Axios;
  globalSettings.axiosIsCancel = isCancel;
  globalSettings.computed = computed;
  globalSettings.ref = ref;
  globalSettings.onMounted = onMounted;
  globalSettings.onDeactivated = onDeactivated;
  globalSettings.onActivated = onActivated;
  globalSettings.vuex = Vuex;
  globalSettings.router = router;
  globalSettings.vueVersion = 'vue3';
  globalSettings.routes = routes;
  globalSettings.nextTick = nextTick;
  globalSettings.jwt = JwtDecode;
  globalSettings.dateFns = dateFns;
  globalSettings.qrcode = QRCode;
  globalSettings.uuidv4 = uuidv4;
  globalSettings.cryptoJs = cryptoJs;
  globalSettings.inject = inject;
  globalSettings.onUnmounted = onUnmounted;
  globalSettings.onBeforeUnmount = onBeforeUnmount;
  globalSettings.sentry = Sentry;
};

setupGlobalSettings();

const showNonChromiumBrowserNotification = () => {
  if (deviceService.os === OperationSystem.Desktop && isSafari) {
    alertService.addAlert({
      type: AlertMessageTypes.Warning,
      timeoutMs: 100000,
      message: 'Для корректной работы приложения используйте Google Chrome',
    });
  }
};

let isInitialized = false;

let runningTimeoutId: number;

async function main() {
  if (runningTimeoutId) {
    window.clearTimeout(runningTimeoutId);
  }

  if (isInitialized) {
    return;
  }

  environmentService.init({});
  // Что-то старое осталось, пока страшно выпиливать
  hideDebugData();
  // Выставляем env переменные плеера
  setupPlayer();
  // Выставляем логгеры
  setupLoggers();
  // В логи кидаем инфу о версии приложения
  showAppVersion();

  requestService.init();

  // Инициализируем системные API
  await deviceService.init();
  alertService.initialize();
  customEventsService.init();
  // активируем хуки авторизации / род. контроля.
  routerService.initialize();
  authService.init();

  showNonChromiumBrowserNotification();

  remoteControlService.initialize();

  try {
    await analyticService.init();

    analyticService.setFeatureFlags({
      [FeatureToggle.AllPlatformTestAA]: featuresService.getFeatureFlag(FeatureToggle.AllPlatformTestAA)?.variant
        ?.value,
    });
  } catch (e) {
    console.error(e);
  }

  const app = createApp(App);

  globalSettings.vue = app;
  app.config.globalProperties.$RouterPage = RouterPage;

  window.$smarttv = {
    SpatialNavigation,
    useNavigatable,
  };

  const { router } = routerService;

  setupSentry(app, router, Sentry);

  app.use(router).use(i18n).use(telemetry);

  await deviceService.resolveStartupDeeplink();

  const visualDebug = process.env.IS_VISUAL_DEBUG_ENABLED === 'true';
  // Можно добавить в .env файл. process.env.IS_NAVIGATION_DEBUG_ENABLED=true
  const debug = process.env.IS_NAVIGATION_DEBUG_ENABLED === 'true';

  SpatialNavigation.init({ debug, visualDebug, throttleKeypresses: true, throttle: 100 });

  const contentStore = useContentStore();
  const sessionStore = useSessionStore();

  const fetchUser = async () => {
    try {
      const user = await sessionStore.fetchUser({ forceFetchUser: true });

      if (user) {
        const uid = user.currentDevice.uid;

        remoteControlService.createSession(uid);
      }
    } catch (error) {
      console.error(error);
    }
  };

  app.component('NavigatableItem', NavigatableItem);
  app.directive('long-press', longPressDirective);

  await Promise.all([
    fetchUser(),
    contentStore.fetchGenres(),
    contentStore.fetchPeriods(),
    contentStore.fetchCountries(),
    sessionStore.fetchOffers(),
  ]);

  app.mount('#app');

  try {
    analyticService.sendEvent({ name: AnalyticEventNameNew.AutoStartSession, page: AnalyticPageName.Main });
  } catch (e) {
    //
  }

  isInitialized = true;
}

const onError = (error: Error) => {
  const app = createApp(SmartTvCriticalErrorView, { error });

  app.mount('#app');
};

const run = () => {
  main().catch((error) => {
    Sentry.captureException(error);
    onError(error);
    run();
  });
};

function getTizenVersion() {
  try {
    const agents = navigator.userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Tizen') {
        return parseFloat(agents[i + 1]);
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}

const tizenVersion = getTizenVersion() as number;
const os = deviceService.getOSInformation();

document.addEventListener('DOMContentLoaded', () => run());

if (os === OperationSystem.WebOs || os === OperationSystem.Desktop) {
  runningTimeoutId = window.setTimeout(() => run(), 1000);
}

if (tizenVersion >= 4 && tizenVersion !== 5) {
  runningTimeoutId = window.setTimeout(() => run(), 1000);
}
